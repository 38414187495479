import React, { Component, Fragment } from "react";
import { TransitionGroup } from 'react-transition-group';

import {
  Row,
  Col,
  ListGroup,
  ListGroupItem,
  Card,
} from "reactstrap";

import { connect } from "react-redux";
import { setOfice } from '../../../actions/authActions'
import { withRouter} from "react-router-dom";

import fondoUnops from "../../../assets/utils/images/dropdown-header/UNOPS_screen_blue_background.jpg";

class CRMDashboard extends Component {
  
  constructor(props) {
    super(props);
      this.state = {
          loading: true,
          idOficinaB:0,
          Contents:[],
      };
    }

    async componentDidMount() {
      //this.GetContents();
    }

  render() {
    const idOficinaB = this.state.idOficinaB;
    let infoUsuario = this.props.auth2.user;
    return (
      <Fragment>
        <TransitionGroup>
          <div >
          <Row>
                <Col md="6" lg="4" xl="4" sm ="6" xs="12">
                  <Card className="card-shadow-primary card-border mb-3">
                    <div className="dropdown-menu-header">
                      <div className="dropdown-menu-header-inner bg-primary">
                        <div className="menu-header-image"
                          //style={{ backgroundImage: "url(" + fondoUnops + ")", }}
                          >
                      </div>
                        <div className="menu-header-content">
                        <div className="avatar-icon-wrapper me-2 avatar-icon-xl">
                            <div className="avatar-icon">
                              <img src={infoUsuario.avatar} alt="Avatar 5" />
                            </div>
                          </div>
                          <div>
                            <h5 className="menu-header-title">{infoUsuario.nombre}</h5>
                            <h6 className="menu-header-subtitle">
                            {infoUsuario.puesto}
                            </h6>
                          </div>

                        </div>
                      </div>
                    </div>
                    <div className="scroll-area-sm">
                        <ListGroup flush>
                          <ListGroupItem>
                          <div className="widget-content p-0">
                          <div className="widget-content-wrapper">
                            <div className="widget-content-left me-3">
                              <div className="icon-wrapper m-0">
                                <div className="progress-circle-wrapper">
                                  <i className="lnr-calendar-full text-muted fsize-4" />
                                </div>
                              </div>
                            </div>
                            <div className="widget-content-left">
                              <div className="widget-heading">Last session</div>
                              <div className="widget-subheading">
                              {new Date(infoUsuario.accessPortal).toLocaleDateString('en-us',{ weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' })}
                              </div>
                            </div>
                            <div className="widget-content-right">
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            </div>
                          </div>
                        </div>
                          </ListGroupItem>
                          <ListGroupItem>
                          <div className="widget-content p-0">
                          <div className="widget-content-wrapper">
                            <div className="widget-content-left me-3">
                              <div className="icon-wrapper m-0">
                                <div className="progress-circle-wrapper">
                                  <i className="lnr-apartment text-muted fsize-4" />
                                </div>
                              </div>
                            </div>
                            <div className="widget-content-left">
                              <div className="widget-heading">Assingned Office</div>
                              <div className="widget-subheading">
                              {infoUsuario.assignedOffice}
                              </div>
                            </div>
                            <div className="widget-content-right">
                            </div>
                          </div>
                        </div>
                          </ListGroupItem>                         
                        </ListGroup>
                    </div>

                  </Card>
                </Col>
              </Row>
          </div>

        </TransitionGroup>
      </Fragment>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    auth: state.setOfice,
    auth2: state.auth
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setOfice: (token) => {
      dispatch(setOfice(token));
    }
  }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CRMDashboard));
//export default CRMDashboard;
import axios from 'axios';
import jwt_decode from "jwt-decode";
import dayjs from 'dayjs'
import config from '../config.json';


    function myCustomAxios (accessToken, refreshToken)
    {
        const headers= {
            "Access-Control-Allow-Origin": true,
            "Content-Type": "application/json",
            Authorization: "Bearer " + accessToken,
        }
    
        const refresh = { 'AccessToken': accessToken, "RefreshToken": refreshToken, "error": "" }
        const vRefresh = JSON.stringify(refresh);
    
        const myAxios = axios.create();

        myAxios.interceptors.request.use(async req => {
            const user = jwt_decode(accessToken);
            const isExpired = dayjs.unix(user.exp).diff(dayjs()) < 1;

            if(!isExpired) return req;
            /*else
            {
                props.history.push({
                    pathname: "/login",
                    query:{},
                  })
            }*/
        
            let respuesta = await axios.put(process.env.REACT_APP_API + config.GOOGLE_AUTH_CALLBACK_URL,
                vRefresh,
                { headers });

        this.props.setToken(respuesta.data);
        req.headers.Authorization = `Bearer ${respuesta.data.accessToken}`
        
        return req;
        })

        return myAxios;
    }

    export default myCustomAxios;